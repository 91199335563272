<template>
    <v-dialog
        v-model="dialogDelete"
        max-width="400px"
    >
    <template v-slot:activator="{ attrs }">
        <v-btn
            color="red"
            dark
            class="mb-2"
            v-bind="attrs"
            @click="openDialogDeleteVoucher()"
        >
        <v-icon left small >fas fa-trash-alt</v-icon> REMOVE VOUCHER
        </v-btn>
    </template>
        <v-card :loading="formUploadCSV.loading">
            <v-card-title class="headline">
            Delete Voucher
            </v-card-title>
            <v-card-actions>
            <v-spacer></v-spacer>
                <v-btn
                    color="green darken-1"
                    text
                    @click="dialogDelete = false"
                >
                    CLOSE
                </v-btn>

                <v-btn
                    color="green darken-1"
                    text
                    @click="removeVoucher()"
                >
                    DELETE
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import * as voucher from '../services/voucher.js'
import { mapState, mapActions } from 'vuex'

export default {
    props: ['selected', 'reloadData'],
    data: () => ({
        dialogDelete: false,
        formUploadCSV: {
            files: [],
            errorShow: false,
            errorMsg: "",
            loading: false
        },
    }),
    methods: {
        ...mapActions('app', ['SNACK_BAR']),
        openDialogDeleteVoucher() {
            if (this.selected.length === 0) {
                this.SNACK_BAR({show: true, text: 'Please select voucher', status: ''})
                return
            }

            if (this.selected.length !== 0)
                this.dialogDelete = true
        },
        async removeVoucher() {
            if (this.selected.length === 0)
                return

            const voucherId = await this.selected.map(data => data.id)
            const campaignId = this.$route.params.campaingId
            const result = await voucher.voucherDelete({ campaignId, voucherId})

            if (result === 'REMOVED')
                this.SNACK_BAR({show: true, text: 'Voucher Removed', status: ''})

            this.dialogDelete = false
            this.reloadData()
        },
    }
}
</script>
