import config from './config'
import * as axios from 'axios'
const API_URL = config.URL_API

export const request = () => {
    return {
        GET(path) {
            return new Promise((resolve, _) => {
                axios.get(`${API_URL}${path}`)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    if (error.response) 
                        resolve(error.response.data)
                })
            })
        }, 
        POST(path, raw) {
            return new Promise((resolve, _) => {
                axios.post(`${API_URL}${path}`, raw)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    if (error.response) 
                        resolve(error.response.data)
                })
            })
        },
        UPLOAD (path, formData) {
            return new Promise((resolve, _) => {
                axios.post(`${API_URL}${path}`, formData)
                .then((response) => {
                    resolve(response.data)
                })
                .catch((error) => {
                    if (error.response) 
                        resolve(error.response.data)
                })
            })
        }
    }
}