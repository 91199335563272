<template>
    <v-dialog 
        persistent
        v-model="dialogEditVoucher" 
        max-width="800px">
        <v-card>
            <v-card-title> Edit Voucher </v-card-title>
            <v-divider class="mx-4"></v-divider>
                <v-card-text>
                    <v-container>
                        <v-row>
                        <v-col cols="12" sm="12" md="12" >
                        <v-alert v-show="formEditVoucherErrorShow"
                                border="right"
                                colored-border
                                type="error"
                                elevation="2"
                            >
                                {{ formEditVoucherErrorMsg }}
                        </v-alert>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" >
                            <v-text-field
                            v-model="formEditVoucher.mobile"
                            label="Mobile"
                            :disabled="formEditVoucher.isUsed"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" >
                            <v-text-field
                            v-model="formEditVoucher.idCard"
                            label="ID Card"
                            :disabled="formEditVoucher.isUsed"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" >
                            <v-text-field
                            v-model="formEditVoucher.voucherCode"
                            label="Voucher Code"
                            :disabled="formEditVoucher.isUsed"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" >
                            <v-text-field
                            v-model="formEditVoucher.voucherValue"
                            label="Voucher Value"
                            :disabled="formEditVoucher.isUsed"
                            ></v-text-field>
                        </v-col>
                        </v-row>
                    </v-container>
                </v-card-text>
            <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="closeDialogEditVoucher()"
        >
            Close
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="saveDialogEditVoucher()"
            :disabled="formEditVoucher.isUsed"
        >
            Save
        </v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
</template>

<script>
import dayjs from 'dayjs'
import * as voucher from '../services/voucher.js'
import * as lib from '../services/lib.js'
import { mapState, mapActions } from 'vuex'

export default {
    props: ['dialogEditVoucher', 'formEditVoucher', 'closeDialogEditVoucher', 'reloadData'],
    data: () => ({
        formEditVoucherErrorShow: false,
        formEditVoucherErrorMsg: "",
    }),
    methods: {
        ...mapActions('app', ['SNACK_BAR']),
        async saveDialogEditVoucher () {
			this.editVoucherLoading = true
			let isNumber = new RegExp('^[0-9]+$')
			let error = []
			if (this.formEditVoucher.mobile.length !== 10) {
                if (!isNumber.test(this.formEditVoucher.mobile)) {
                    error.push('Mobile needs to be digits.')
                } else {
                    error.push('Mobile needs to be 10 digits.')
                }
			}
			
			if (!isNumber.test(this.formEditVoucher.idCard))
			    error.push('ID Card needs to be digits.')

			if (!isNumber.test(this.formEditVoucher.voucherValue)) 
			    error.push('VoucherValue needs to be digits.')

			if (error.length > 0) {
                this.formEditVoucherErrorShow = true
                this.formEditVoucherErrorMsg = error.join(" ")
                return
			}

			const result = await voucher.voucherUpdate({
                campaignId: this.$route.params.campaingId,
                voucherId: this.formEditVoucher.voucherId,
                mobile: this.formEditVoucher.mobile,
                idCard: this.formEditVoucher.idCard,
                voucherCode: this.formEditVoucher.voucherCode,
                voucherValue: this.formEditVoucher.voucherValue
            })

            if (result.status === 'INVALID_PARAMETER') {
                error.push(`${result.msg}`)
                this.formEditVoucherErrorShow = true
                this.formEditVoucherErrorMsg = error.join(" ")
                return
            }

			if (result.status === 'UPDATED') {
                this.SNACK_BAR({show: true, text: 'Voucher Updated', status: ''})
                this.reloadData()
			}
            this.closeDialogEditVoucher()
		},
    }
}
</script>
