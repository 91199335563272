import { request } from './API'

export const isCampaignName = async (camapignName) => {
    const response = await request().GET(`/manager/campaign/name/${camapignName}`)
    return response.data.length > 0 ? true : false
}

export const getVoucherByCampaignId = async (camapignId) => {
    const response = await request().GET(`/manager/voucher/campaignId/${camapignId}`)
    return response.data
}

export const getVoucherImageByCampaignId = async (camapignId) => {
    const response = await request().GET(`/manager/voucher/campaignId/${camapignId}/images`)
    return response.data
}

export const uploadVoucherImage = async (fileInput, campaignId, value) => {
    const formData = new FormData();
    formData.append("fileName", fileInput)
    formData.append("campaignId", campaignId)
    formData.append("voucherValue", value)
    const response = await request().UPLOAD('/manager/upload-voucher', formData)
    if (response.status === 'UPLOADED')
        return response.status
    
    return 'ERROR'
}

export const uploadVoucher = async (fileInput, campaignId) => {
    const formData = new FormData();
    formData.append("fileName", fileInput)
    formData.append("campaignId", campaignId)
    const response = await request().UPLOAD('/manager/upload-csv', formData)
    return response
}

export const voucherUpdate = async ({ campaignId, voucherId, mobile, idCard, voucherCode, voucherValue }) => {
    const response = await request().POST('/manager/voucher/update', {
        campaignId,
        voucherId,
        mobile,
        idCard,
        voucherCode,
        voucherValue
    })
    return response
}   


export const voucherCreate = async ({ campaignId, voucherId, mobile, idCard, voucherCode, voucherValue }) => {
    const response = await request().POST('/manager/voucher/create', {
        campaignId,
        voucherId,
        mobile,
        idCard,
        voucherCode,
        voucherValue
    })
    return response.status
}   


export const voucherDelete = async ({ campaignId, voucherId }) => {
    const response = await request().POST('/manager/voucher/remove', { campaignId, voucherId })
    return response.status
}   