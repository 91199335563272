import Vue from 'vue'
import VueRouter from 'vue-router'
import Campaign from '../views/Campaign.vue'
import Voucher from '../views/Voucher.vue'
import Login from '../views/Login.vue'
import * as axios from 'axios'
import store from '../store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Campaign',
    component: Campaign,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/voucher/campaing/:campaingId',
    name: 'Voucher',
    component: Voucher,
    meta: { requiresAuth: true }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})



const loggedIn = () => {
  const token = localStorage.getItem('token')
	if (token) {
		return true
  	}
  	return false
}

router.beforeEach(async (to, from, next) => {

	const token = localStorage.getItem('token')
	if (token) {
		axios.defaults.headers.common = {'Authorization': token}
		console.log('token', token)
		axios.interceptors.request.use((response) => {
			return response
		}, (error) => {
			return Promise.reject(error)
		})

		axios.interceptors.response.use((response) => {
			return response
		}, (error) => {
			if (`${error}`.includes("401"))
				store.dispatch('authentication/FETCH_LOGOUT')

			return Promise.reject(error)
		})
	}

	if (to.matched.some(record => record.meta.requiresAuth)) {
		if (!loggedIn()) {
			next({
				path: '/login',
				query: { redirect: to.fullPath }
			})
		} else {
			next()
		}
	} else {
		next() 
	}
})

export default router
