import { request } from './API'

export const compaignList = async () => {
    const response = await request().GET('/manager/campaigns')
    return response.data
}

export const isCampaignName = async (camapignName) => {
    const response = await request().GET(`/manager/campaign/name/${camapignName}`)
    return response.data.length > 0 ? true : false
}

export const getCampaignByName = async () => {
    const response = await request().GET('/manager/campaign/name/storetest')
    console.log(response)
}

export const createCampaign = async ({ code, headerBanner, startDate, endDate, status, condition }) => {
    const response = await request().POST('/manager/campaigns/create', {
        code: code,
        headerBanner: headerBanner,
        startDate: startDate,
        endDate: endDate,
        status: status,
        condition: condition,
        isidentifier: false,
        ismatchreward: "MOBILE_AND_IDCARD"
    })
    return response
}

export const updateCampaign = async ({id, code, headerBanner, startDate, endDate, status, condition }) => {
    const response = await request().POST('/manager/campaigns/update', {
        id: id,
        code: code,
        headerBanner: headerBanner,
        startDate: startDate,
        endDate: endDate,
        status: status,
        condition: condition,
        isidentifier: false,
        ismatchreward: "MOBILE_AND_IDCARD"
    })
    return response
}

export const uploadBannerCampaign = async (fileInput) => {
    const formData = new FormData();
    formData.append("imageName", fileInput)
    formData.append("type", "BANNER")
    formData.append("state", "CREATE")
    formData.append("imageDelete", "")
    const response = await request().UPLOAD('/manager/upload-images', formData)
    if (response.status === 'UPLOADED') {
        return {
            status: 'SUCCESS',
            msg: `${response.data.imageName}`
        }
    }
    return {
        status: 'ERROR',
        msg: response.msg
    }
    
}

export const uploadUpdateBannerCampaign = async (fileInput, imageName) => {
    const formData = new FormData();
    formData.append("imageName", fileInput)
    formData.append("type", "BANNER")
    formData.append("state", "UPDATE")
    formData.append("imageDelete", imageName)
    const response = await request().UPLOAD('/manager/upload-images', formData)
    if (response.status === 'UPLOADED') {
        return {
            status: 'SUCCESS',
            msg: `${response.data.imageName}`
        }
    }
    return {
        status: 'ERROR',
        msg: response.msg
    }
}