<template>
    <div>
        <v-btn
        color="primary"
        dark
        class="mb-2"
        @click="openDialogCreateVoucher"
    >
        <v-icon left small >fas fa-file-csv</v-icon> Create Voucher
    </v-btn>
    <v-dialog 
        persistent
        v-model="dialogCreateVoucher" 
        max-width="800px">
        <v-card>
            <v-card-title> Create Voucher </v-card-title>
                <v-card-text>
                    <v-row>
                        <v-col cols="12" sm="12" md="12" >
                        <v-alert v-show="formEditVoucherErrorShow"
                                border="right"
                                colored-border
                                type="error"
                                elevation="2"
                            >
                                {{ formEditVoucherErrorMsg }}
                        </v-alert>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" >
                            <v-text-field
                            v-model="formCreateVoucher.mobile"
                            label="Mobile"
                            :disabled="formCreateVoucher.isUsed"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" >
                            <v-text-field
                            v-model="formCreateVoucher.idCard"
                            label="ID Card"
                            :disabled="formCreateVoucher.isUsed"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" >
                            <v-text-field
                            v-model="formCreateVoucher.voucherCode"
                            label="Voucher Code"
                            :disabled="formCreateVoucher.isUsed"
                            ></v-text-field>
                        </v-col>
                        <v-col cols="12" sm="12" md="12" >
                            <v-text-field
                            v-model="formCreateVoucher.voucherValue"
                            label="Voucher Value"
                            :disabled="formCreateVoucher.isUsed"
                            ></v-text-field>
                        </v-col>
                    </v-row>
                </v-card-text>
            <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
            color="blue darken-1"
            text
            @click="dialogCreateVoucher = false"
        >
            Close
        </v-btn>
        <v-btn
            color="blue darken-1"
            text
            @click="saveDialogCreateVoucher"
        >
            Save
        </v-btn>
        </v-card-actions>
    </v-card>
    </v-dialog>
    </div>
</template>

<script>
import * as voucher from '../services/voucher.js'

export default {
    props: ['dialogEditVoucher', 'reloadData', 'snackbarAlert'],
    data: () => ({
        dialogCreateVoucher: false,
        formEditVoucherErrorShow: false,
        formEditVoucherErrorMsg: "",
        formCreateVoucher: {
			voucherId: '',
			mobile: '',
			idCard: '',
			voucherCode: '',
			voucherValue: '',
			isUsed: false,
			errorShow: false,
		},
    }),
    methods:{
        openDialogCreateVoucher () {
			this.dialogCreateVoucher = true
        },
        async saveDialogCreateVoucher () {
            this.editVoucherLoading = true
			let isNumber = new RegExp('^[0-9]+$')
			let error = []
			if (this.formCreateVoucher.mobile.length !== 10) {
                if (!isNumber.test(this.formCreateVoucher.mobile)) {
                    error.push('Mobile needs to be digits.')
                } else {
                    error.push('Mobile needs to be 10 digits.')
                }
			}
			
			if (!isNumber.test(this.formCreateVoucher.idCard))
			    error.push('ID Card needs to be digits.')

			if (!isNumber.test(this.formCreateVoucher.voucherValue)) 
			    error.push('VoucherValue needs to be digits.')

			if (error.length > 0) {
                this.formEditVoucherErrorShow = true
                this.formEditVoucherErrorMsg = error.join(" ")
                return
			}

            console.log(this.formCreateVoucher)
			const result = await voucher.voucherCreate({
                campaignId: this.$route.params.campaingId,
                voucherId: this.formCreateVoucher.voucherId,
                mobile: this.formCreateVoucher.mobile,
                idCard: this.formCreateVoucher.idCard,
                voucherCode: this.formCreateVoucher.voucherCode,
                voucherValue: this.formCreateVoucher.voucherValue
            })
            
			if (result === 'CREATED') {
                this.snackbarAlert(true, 'Voucher Created')
                this.reloadData()
            }
            
            this.dialogCreateVoucher = false
            // this.closeDialogEditVoucher()
        },
        templateDownload () {
			window.location.assign(`http://localhost:3000/uploads/template/template.csv`)
		}
    }
}
</script>