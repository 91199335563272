import Vue from 'vue'
import Vuex from 'vuex'

import app from './modules/app'
import authentication from './modules/authentication'

Vue.use(Vuex)

export default new Vuex.Store({
    namespaced: true,
    modules: {
        app,
        authentication
    }
})
