<template>
	<div class="about" style="margin-top: 40px;">
		<v-card
			class="mx-auto"
			max-width="500"
		>

		<v-toolbar color="blue-grey darken-3" dark>
			<v-toolbar-title>Manager Campaign</v-toolbar-title>
			<v-spacer></v-spacer>
		</v-toolbar>

			<v-card-text>
				<v-container>
					<v-form ref="form">
						<v-row>
							<v-col 
								cols="12"
								sm="12"
								md="12"
							>
								<v-text-field
								label="Username"
								outlined
								placeholder="USERNAME"
								v-model="formLogin.username"
								:rules="[v => !!v || 'Username is required']"
								></v-text-field>
							</v-col>
							<v-col 
								cols="12"
								sm="12"
								md="12"
							>
								<v-text-field
								label="Password"
								type="password"
								outlined
								placeholder="PASSWORD"
								v-model="formLogin.password"
								:rules="[v => !!v || 'Password is required']"
								></v-text-field>
							</v-col>
							<v-col 
								cols="12"
								sm="12"
								md="12"
							>
								<v-btn 
									block
									color="blue-grey darken-3"
									elevation="11"
									large
									x-large
									dark
									@click="login"
								>
									Login
								</v-btn>
							</v-col>
						</v-row>
					</v-form>
				</v-container>
			</v-card-text>
		</v-card>
	</div>
</template>
<script>
import dayjs from 'dayjs'
import * as user from '../services/user.js'
import * as lib from '../services/lib.js'
import _ from 'lodash'
import { mapState, mapActions } from 'vuex'

  export default {
    data: () => ({
        formLogin: {
            username: "",
            password: ""
        },
        rules: {
          required: value => !!value || `Required.`,
        },
	}),
    methods: {
		...mapActions('authentication', ['FETCH_LOGIN']),
		...mapActions('app', ['SNACK_BAR']),
		async login () {
			if(_.isEmpty(this.formLogin.username)) {
			console.log(_.isEmpty(this.formLogin.username))
			this.$refs.form.validate()
			return
			}

			if(_.isEmpty(this.formLogin.password)) {
			console.log(_.isEmpty(this.formLogin.password))
			return
			}
			await this.FETCH_LOGIN({ username: this.formLogin.username, password: this.formLogin.password })
		},
		logOut () {
			user.logOut()
			this.$router.push('/login')
		}
    },
  }
</script>