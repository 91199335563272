<template>
    <div>
        <v-card class="mx-auto" outlined>
            <v-row dense style="padding: 10px;">
            <v-col
                v-for="(item, index) in voucherImages.length" :key="index"
                class="d-flex child-flex"
                cols="4"
                sm="12"
                md="4"
            >

                <v-card class="mx-auto">
                <v-img
                    class="white--text align-end"
                    height="250px"
                    :src="`${voucherImages[index].imageUrl}`"
                    :lazy-src="`${voucherImages[index].imageUrl}`"
                    @click="editVoucherImage(voucherImages[index])"
                >
                    <v-card-title style="background: black; opacity: 0.8;">
                        <div style=""><span>{{ `VALUE : ${voucherImages[index].voucherValue}` }}</span></div>
                    </v-card-title>
                </v-img>
                </v-card>

            </v-col>
            </v-row>
        </v-card>
        <v-row justify="center">
            <v-dialog
                v-model="dialogUpload"
                persistent
                max-width="600px"
            >
                <v-card>
                    <v-card-title></v-card-title>
                    <v-card-text>
                        <v-row>
                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                        <v-text-field
                            label="Voucher Vaule"
                            v-model="formUpload.voucherValue"
                            disabled
                        ></v-text-field>
                        </v-col>

                        <v-col
                            cols="12"
                            sm="12"
                            md="12"
                        >
                            <v-file-input
                            v-model="formUpload.files"
                            color="deep-purple accent-4"
                            counter
                            label="File input"
                            placeholder="Select your files"
                            prepend-icon="mdi-paperclip"
                            outlined
                            :show-size="1000"
                            >
                            <template v-slot:selection="{ index, text }">
                                <v-chip
                                v-if="index < 2"
                                color="deep-purple accent-4"
                                dark
                                label
                                small
                                >
                                {{ text }}
                                </v-chip>

                                <span
                                v-else-if="index === 2"
                                class="overline grey--text text--darken-3 mx-2"
                                >
                                +{{ files.length - 2 }} File(s)
                                </span>
                            </template>
                            </v-file-input>
                        </v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="dialogUpload = false"
                        >
                            Close
                        </v-btn>
                        <v-btn
                            color="blue darken-1"
                            text
                            @click="uploadVoucherImage"
                        >
                            Save
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
    </div>
</template>

<script>
import * as voucher from '../services/voucher.js'
import { mapState, mapActions } from 'vuex'

export default {
    props: ['voucherImages', 'loadVoucherImage'],
    data: () => ({
        dialogUpload: false,
        formUpload: {
            voucherValue: "",
            files: []
        },
    }),
    methods: {
        ...mapActions('app', ['SNACK_BAR']),
        async editVoucherImage (item) {
            this.dialogUpload = true
            const voucherValue = item.voucherValue
            const campaignId = this.$route.params.campaingId
            this.formUpload.voucherValue = voucherValue 
        },
        async uploadVoucherImage () {
            const campaignId = this.$route.params.campaingId
            const file = this.formUpload.files
            const voucherValue = this.formUpload.voucherValue
            if (file.length === 0) {
                this.SNACK_BAR({show: true, text: 'Please select image', status: ''})
                return
            }
            const resposne = await voucher.uploadVoucherImage(file, campaignId, voucherValue)
            this.SNACK_BAR({show: true, text: 'Voucher Image Updated', status: ''})
            this.loadVoucherImage()
            this.dialogUpload = false

        },
    }
}
</script>
