<template>
  <v-data-table
	:headers="headers"
	:items="campaignData"
	class="elevation-1"
	:search="search"
	sort-by="code"
	:custom-filter="filterOnlyCapsText"
  >
	<template v-slot:top>
		<v-snackbar
		v-model="snackbar"
		>
		{{ text }}

		<template v-slot:action="{ attrs }">
			<v-btn
			color="red"
			text
			v-bind="attrs"
			@click="snackbar = false"
			>
			Close
			</v-btn>
		</template>
		</v-snackbar>

	  	<v-toolbar>
			<v-toolbar-title>Campaign</v-toolbar-title>
			<v-divider
				class="mx-10"
				inset
				vertical
			>
			</v-divider>
			<v-text-field
				v-model="search"
				append-icon="mdi-magnify"
				label="Search all fields"
				single-line
				hide-details
			>
			</v-text-field>
			
			<v-spacer></v-spacer>
		<CampaignCreate :initialize="initialize"></CampaignCreate>
	  </v-toolbar>
	</template>

	<template v-slot:[`header.VoucherStatus`]="{ header }">
      	<div style="text-align: center;">{{ header.text }}</div>
    </template>

	<template v-slot:[`item.VoucherStatus`]="{ item }">
		<div style="text-align: center;"><v-btn color="primary" dark small text ><span style="width: 100px; text-align: right; margin-right: 10px;">{{ item.voucherAmount }} EA</span> | <span style="width: 100px; margin-left: 10px; text-align: left;">USE {{ item.voucherActive }}</span></v-btn></div>
	</template>

	<template v-slot:[`header.status`]="{ header }">
      	<div style="text-align: center;">{{ header.text }}</div>
    </template>

	<template v-slot:[`item.status`]="{ item }">
		<v-chip v-show="item.status == 'ACTIVE'"
			class="ma-2"
			color="primary"
			text-color="white"
		>
      		ACTIVE
    	</v-chip>
		<v-chip v-show="item.status == 'INACTIVE'"
			class="ma-2"
			text-color="white"
		>
      		INACTIVE
    	</v-chip>
	</template>
	<template v-slot:[`item.isExpireDate`]="{ item }">
		<v-chip v-show="item.isExpireDate == 'Expire'"
			class="ma-2"
			color="red"
			text-color="white"
		>
      		EXPIRE
    	</v-chip>
	</template>

	<template v-slot:[`item.actionVoucher`]="{ item }">
		<v-btn color="primary" dark small @click="toVoucherPage(item)"><v-icon left small >fas fa-archive</v-icon> Voucher</v-btn>
	</template>

	<template v-slot:[`item.actionCampaign`]="{ item }">
		<CampaignEdit :item="item" :initialize="initialize"></CampaignEdit>
		|
		<CampaignCopyLink :item="item"></CampaignCopyLink>
	</template>

  </v-data-table>
</template>

<script>
import dayjs from "dayjs";
import * as campaign from "../services/campaign.js";
import config from '../services/config'
import { mapState, mapActions } from 'vuex'
import CampaignCreate from '../components/CampaignCreate'
import CampaignCopyLink from '../components/CampaignCopyLink'
import CampaignEdit from '../components/CampaignEdit'

export default {
	components: {
		CampaignCreate,
		CampaignEdit,
		CampaignCopyLink
	},
	data: () => ({
		snackbar: false,
		text: '',
		dialog: false,
		dialogDelete: false,
		toolTipCopy: false,
		date: new Date().toISOString().substr(0, 10),
		modal: false,
		search: '',
		headers: [
			{ text: "Campaign Name", value: "code", align: "start" },
			{ text: "Start Date", value: "startDate" },
			{ text: "End Date", value: "endDate" },
			{ text: "Voucher Balance", value: "VoucherStatus", sortable: false },
			{ text: "Actions Voucher", value: "actionVoucher", sortable: false, align: "center" },
			{ text: "Status", value: "status", sortable: false, align: "center" },
			{ text: "Expire", value: "isExpireDate", sortable: false, align: "center" },
			{ text: "Actions Campaign", value: "actionCampaign", sortable: false, align: "center" },
		],
		campaignData: [],
	}),
	created() {
		this.initialize()
	},
	methods: {
		...mapActions('app', ['SNACK_BAR']),
		async initialize() {
			let data = await campaign.compaignList()
			data.map((data) => {
					data.startDate = dayjs(data.startDate).format("YYYY-MM-DD");
					data.endDate = dayjs(data.endDate).format("YYYY-MM-DD");
					data.status = data.status === "use" ? "ACTIVE" : "INACTIVE";
					data.isExpireDate = dayjs(data.endDate).diff(dayjs(), "minute") > 0 ? '' : 'Expire';
				return data;
			});
			this.campaignData = data;
		},
		filterOnlyCapsText(value, search, item) {
			let searchText = value != null && search.toLocaleUpperCase() != null && typeof value === "string" && value
				.toString()
				.toLocaleUpperCase()
				.indexOf(search.toLocaleUpperCase()) !== -1
			if (search.length > 0 && value != null && search.toLocaleUpperCase() != null && typeof value === "string") {
				return new RegExp("^"+search.toLocaleUpperCase()+"").test(value.toLocaleUpperCase())
			}
			return false
		},
		toVoucherPage(item) {
			this.$router.push({ path: `/voucher/campaing/${item.id}` });
		}
	},
};
</script>
