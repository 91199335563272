<template>
    <div style="display: inline;">
        <v-btn color="primary" dark small @click="openDialogLink()"><v-icon left small >fas fa-copy</v-icon> COPY LINK</v-btn>

        <v-dialog
        v-model="dialogGetLink"
        hide-overlay
        max-width="500px"
        >
            <v-card>
                <v-card-title></v-card-title>
                <v-card-text style="padding-bottom: 0px;">
                    <v-text-field
                        v-model="linkCampign"
                        label="LINK CAMPAIGN"
                        disabled
                    ></v-text-field>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        color="deep-purple lighten-2"
                        text
                        @click="copyLink"
                    >
                        <v-icon left small >fas fa-copy</v-icon> COPY
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import dayjs from "dayjs";
import * as campaign from "../services/campaign.js";
import config from '../services/config'
import { mapState, mapActions } from 'vuex'
import CampaignCreate from '../components/CampaignCreate'

export default {
    props: ['item'],
    data: () => ({
		dialogGetLink: false,
		linkCampign: null,
	}),
    methods: {
        ...mapActions('app', ['SNACK_BAR']),
        openDialogLink () {
            this.dialogGetLink = true
            this.linkCampign = `${config.URL_FRONTEND_CAMPAIGN}?c=${this.item.code}`
	    },
        copyLink () {
            navigator.clipboard.writeText(this.linkCampign)
            .then(() => { 
                this.SNACK_BAR({show: true, text: `COPIED ${this.linkCampign}`, status: ''})
                this.dialogGetLink = false
             })
            .catch((error) => { 
                this.SNACK_BAR({show: true, text: `COPY FAILED ${this.linkCampign}`, status: ''})
            })
        }
    }
}
</script>

<style>

</style>