<template>
    <v-app id="inspire">
        <v-app-bar
            app  
            color="indigo darken-2"
            dark
            scroll-target="#scrolling-techniques"
            v-show="isAppBar"
        >
            <v-btn text v-show="isBtnBack" @click="goBack">
                <v-icon
                    dark
                    left
                >
                mdi-arrow-left
                </v-icon>Back
            </v-btn>
            <v-toolbar-title>Manager Campaign</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn text @click="logout"> LogOut  </v-btn>
        </v-app-bar>
        <v-main>
            <router-view/>
        </v-main>
        <SnackBar></SnackBar>
    </v-app>
</template>

<script>
import SnackBar from './components/Snackbar'
import { mapState, mapActions } from 'vuex'

export default {
    data: () => ({
        isBtnBack: false,
        isAppBar: false,
    }),
    updated() {
        this.isAuth()
        this.isMenu()
    },
    mounted() {
        this.isAuth()
        this.isMenu()
    },
    components: {
        SnackBar
    },
    computed: {
        ...mapState('authentication', ['token'])
    },
    methods: {
        ...mapActions('authentication', ['FETCH_LOGOUT']),
        goBack () {
            this.$router.push('/')
        },
        logout () {
            this.FETCH_LOGOUT()
        },
        isAuth () {
            if (localStorage.getItem('token')) {
                this.isAppBar = true
            }
        },
        isMenu () {
            if (this.$route.name === 'Campaign') {
                this.isBtnBack = false
            }

            if (this.$route.name === 'Voucher') {
                this.isBtnBack = true
            }

            if (this.$route.name === 'Login') {
                this.isAppBar = false
            }
            
        }
    },
}
</script>