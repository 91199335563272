import { request } from './API'

export const isAuth = async () => {
    const response = await request().GET('/manager/user/is-auth')
    return response
}

export const login = async ({ username, password }) => {
    const response = await request().POST('/manager/user/login', {
        username,
        password
    })
    return response
}

export const logOut = () => {
    localStorage.removeItem('token')
}