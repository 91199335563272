import * as user from '../../services/user'
import router from '../../router'

const state = {
    username: null,
    token: null,
    isLoading: false,
    login: {
        isLoad: false
    }
}

const mutations = {
    IS_LOADING (state, payload) {
        state.isLoading = payload
    },
    SET_TOKEN (state, payload) {
        state.token = payload
    }
}

const actions = {
    async FETCH_LOGIN ({ commit, dispatch }, payload) {
        const { username, password } = payload
        const response = await user.login({ username, password })
        if (response.status === 'AUTHORIZED') {
            localStorage.setItem('token', response.token)
            commit('SET_TOKEN', response.token)
            router.push('/')
        } else {
            await dispatch('app/SNACK_BAR', {show: true, text: 'username or password not match', status: 'error'}, {root: true})
        }
    },
    async FETCH_LOGOUT ({ commit, dispatch }, payload) {
        commit('SET_TOKEN', null)
        localStorage.removeItem('token')
        router.push('/login')
    }
}

const getters = {
    token: state => state.token || null
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
