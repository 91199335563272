const state = {
    isDrawer: true,
    snackbar: {
        rightBottom: {
            show: false,
            x: true,
            y: 'bottom',
            mode: '',
            timeout: 6000,
            text: 'rightBottom',
            status: ''
        },
    }
}

const mutations = {
    CHANGE_DRAWER (state) {
        state.isDrawer = state.isDrawer === true ? false : true
    },
    SNACK_BAR (state, payload) {
        state.snackbar.rightBottom.show = payload.show
        state.snackbar.rightBottom.text = payload.text
        state.snackbar.rightBottom.status = payload.status
    },
    SNACK_BAR_RIGHT_BOTTOM_CLOSE (state) {
        state.snackbar.rightBottom.show = false
    }
}

const actions = {
    CHANGE_DRAWER({ commit }) {
        commit('CHANGE_DRAWER')
    },
    SNACK_BAR({ commit }, payload) {
        commit('SNACK_BAR', payload)
    },
    SNACK_BAR_RIGHT_BOTTOM_CLOSE({ commit }) {
        commit('SNACK_BAR_RIGHT_BOTTOM_CLOSE')
    }
}

const getters = {}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}