<template>
    <v-snackbar
        v-model="snackbar.rightBottom.show"
        :bottom="snackbar.rightBottom.bottom"
        :right="snackbar.rightBottom.x"
        :timeout="snackbar.rightBottom.timeout"
        :top="snackbar.rightBottom.top"
        :vertical="snackbar.rightBottom.vertical"
    >
        {{ snackbar.rightBottom.text }}
        <template v-slot:action="{ attrs }">
        <v-btn
          color="blue"
          text
          v-bind="attrs"
          @click="SNACK_BAR_RIGHT_BOTTOM_CLOSE"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
</template>

<script>
import { mapState, mapActions } from 'vuex'

export default {
    data: () => ({
    }),
    computed: {
        ...mapState('app', ['snackbar'])
    },
    methods: {
        ...mapActions('app', ['SNACK_BAR_RIGHT_BOTTOM_CLOSE'])
    }
}
</script>