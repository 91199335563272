<template>
    <v-dialog
        v-model="dialogUploadCSV"
        max-width="800px"
    >
    <template v-slot:activator="{ on, attrs }">
        <v-btn
        color="primary"
        dark
        class="mb-2"
        v-bind="attrs"
        v-on="on"
        >
        <v-icon left small >fas fa-file-csv</v-icon> Import Voucher .CSV
        </v-btn>
    </template>
        <v-card :loading="formUploadCSV.loading">
            <v-card-text>

                <v-row>
                <v-col cols="12" sm="12" md="12" >
                <v-alert v-show="formUploadCSV.errorShow"
                        border="right"
                        colored-border
                        type="error"
                        elevation="2"
                    >
                        {{ formUploadCSV.errorMsg }}
                </v-alert>
                </v-col>
                <v-col cols="12" sm="12" md="12" >
                    <v-file-input
                    v-model="formUploadCSV.files"
                    color="deep-purple accent-4"
                    counter
                    accept=".csv"
                    label="File .CSV"
                    placeholder="Select your files"
                    prepend-icon="mdi-paperclip"
                    outlined
                    :show-size="1000"
                    >
                    <template v-slot:selection="{ index, text }">
                        <v-chip
                        v-if="index < 2"
                        color="deep-purple accent-4"
                        dark
                        label
                        small
                        >
                        {{ text }}
                        </v-chip>
                        <span v-else-if="index === 2" class="overline grey--text text--darken-3 mx-2" >
                        +{{ files.length - 2 }} File(s)
                        </span>
                    </template>
                    </v-file-input>
                </v-col>
                </v-row>
            </v-card-text>
            <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
                color="blue darken-1"
                text
                @click="dialogUploadCSV = false"
            >
                Cancel
            </v-btn>
            <v-btn
                color="blue darken-1"
                text
                @click="uploadCSV"
            >
                Upload CSV
            </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import * as voucher from '../services/voucher.js'
import { mapState, mapActions } from 'vuex'

export default {
    props: ['reloadData', 'snackbarAlert'],
    data: () => ({
        dialogUploadCSV: false,
        formUploadCSV: {
            files: [],
            errorShow: false,
            errorMsg: "",
            loading: false
        },
    }),
    methods: {
        ...mapActions('app', ['SNACK_BAR']),
        async uploadCSV () {
            if (this.formUploadCSV.files.length === 0) {
                this.formUploadCSV.errorShow = true
                this.formUploadCSV.errorMsg = "Please select a file .CSV"
                return
            }

            const fileType = this.formUploadCSV.files.name.split(".")[1].toUpperCase()
            if (fileType !== 'CSV') {
                this.formUploadCSV.errorShow = true
                this.formUploadCSV.errorMsg = "Please select a file .CSV"
                return
            }

            this.formUploadCSV.loading = true
            const campaignId = this.$route.params.campaingId
            const file = this.formUploadCSV.files
            const resposne = await voucher.uploadVoucher(file, campaignId)

            if (resposne.status === 'INVALID') {
                this.formUploadCSV.errorShow = true
                this.formUploadCSV.errorMsg = resposne.msg
                this.formUploadCSV.loading = false
                return
            }

            this.SNACK_BAR({show: true, text: `IMPORT VOUCHERE VOUCHERE CREATED: ${resposne.data.voucherCreated} | VOUCHERE DUPLICATE: ${resposne.data.voucherDuplicate}`, status: ''})
            this.formUploadCSV.loading = false
            this.reloadData()
            this.formUploadCSV.errorShow = false
            this.dialogUploadCSV = false
        }, 
    }
}
</script>
