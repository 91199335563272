<template>
    <v-dialog
        v-model="dialog"
        max-width="1000px"
        persistent
        >
            <template v-slot:activator="{ on, attrs }">
                <v-btn
                    color="primary"
                    dark
                    class="mb-2"
                    v-bind="attrs"
                    v-on="on"
                    @click="openModalCampaign"
                >
                <v-icon left small >fas fa-plus-square</v-icon> CREATE CAMPAIGN
                </v-btn>
            </template>
        <v-card>
            
        <v-card-title> CREATE CAMPAIGN </v-card-title>
        <v-divider class="mx-4"></v-divider>
        <v-card-text>
            <v-container>
                <v-alert 
                    prominent
                    type="error"
                    v-show="isErrorModalCampaign" 
                >
                    {{ msgErrorModalCampaign }}
                </v-alert>

            <v-row>
                <v-col cols="10" sm="10" md="10" >
                    <v-text-field 
                        label="Campaign Name"
                        v-model="form.camapignName"
                    ></v-text-field>
                </v-col>

                <v-col cols="2" sm="2" md="2" >
                    <v-switch
                    v-model="form.status"
                    inset
                    :label="`Status`"
                    ></v-switch>
                </v-col>

                <v-col cols="12" sm="12">
                    <v-dialog
                        ref="dialog"
                        v-model="modal"
                        :return-value.sync="date"
                        persistent
                        width="290px"
                    >
                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="dateRangeText"
                                label="Start Date ~ End Date"
                                prepend-icon="mdi-calendar"
                                readonly
                                v-bind="attrs"
                                v-on="on"
                            ></v-text-field>
                        </template>
                        <v-date-picker
                            v-model="form.startDateEndDate"
                            range
                        >
                        <v-spacer></v-spacer>
                            <v-btn
                                text
                                color="primary"
                                @click="modal = false"
                            >
                            Cancel
                            </v-btn>
                            <v-btn
                            text
                            color="primary"
                            @click="$refs.dialog.save(date)"
                            >
                            OK
                            </v-btn>
                        </v-date-picker>
                    </v-dialog>
                </v-col>
                
                <v-col cols="12" sm="12" md="12" >
                    <v-textarea
                        v-model="form.conditionText"
                        filled
                        clear-icon="mdi-close-circle"
                        label="Condition Text"
                        auto-grow
                    ></v-textarea>
                </v-col>

                <v-col cols="12" sm="12" md="12" >
                    <v-file-input
                        v-model="form.imageFile"
                        color="deep-purple accent-4"
                        label="File Image Banner"
                        placeholder="Select your files"
                        prepend-icon="mdi-paperclip"
                        outlined
                        :show-size="1000"
                        accept="image/*"
                    >
                    <template v-slot:selection="{ index, text }">
                        <v-chip
                            v-if="index < 2"
                            color="deep-purple accent-4"
                            dark
                            label
                            small
                        >
                            {{ text }}
                        </v-chip>
                        <span
                            v-else-if="index === 2"
                            class="overline grey--text text--darken-3 mx-2"
                        >
                            +{{ form.imageFile.length - 2 }} File(s)
                        </span>
                    </template>
                    </v-file-input>
                </v-col>

                <v-col cols="12" sm="4" md="4" style="text-align: -webkit-center;">
                    <v-img
                        :src="form.imageView"
                        v-show="form.imageView != ''"
                    >
                    </v-img>
                </v-col>
                
            </v-row>
            </v-container>
        </v-card-text>

        <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
            color="blue darken-1"
            text
            @click="dialog = false"
            >
            Cancel
            </v-btn>
            <v-btn
            color="blue darken-1"
            text
            @click="saveModalCampaign"
            >
            Save
            </v-btn>
        </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import dayjs from "dayjs"
import * as campaign from "../services/campaign.js"
import config from '../services/config'
import { mapState, mapActions } from 'vuex'

export default {
    props: ['initialize'],
    data: () => ({
		dialog: false,
		date: new Date().toISOString().substr(0, 10),
		modal: false,
		form: {
			name: "",
			camapignName: "",
			matchReward: "",
			startDateEndDate: [
				dayjs().format("YYYY-MM-DD"),
				dayjs().add(7, "days").format("YYYY-MM-DD"),
			],
			conditionText: "",
			status: "",
			imageFile: [],
			imageView: "",
		},
		isErrorModalCampaign: false,
		msgErrorModalCampaign: [],
		files: [],
		campaignData: [],
		editedIndex: -1,
    }),
    computed: {
        dateRangeText() {
            return this.form.startDateEndDate.join(" ~ ")
        }
    },
    methods: {
        ...mapActions('app', ['SNACK_BAR']),
        async openModalCampaign() {
            this.dialog = true
            console.log(this.form)
            this.form = {
                name: "Create Campaign",
                camapignName: "",
                matchReward: "",
                startDateEndDate: [
                    dayjs().format("YYYY-MM-DD"),
                    dayjs().add(7, "days").format("YYYY-MM-DD"),
                ],
                conditionText: "",
                status: true,
                imageFile: [],
                imageView: "",
            }
            this.isErrorModalCampaign = false
            this.msgErrorModalCampaign = ''
        },
        async saveModalCampaign () {
            const isDate = dayjs(this.form.startDateEndDate[1]).diff(dayjs(this.form.startDateEndDate[0]), 'day')
            if (this.form.camapignName === "") {
                this.isErrorModalCampaign = true
                this.msgErrorModalCampaign = 'Please enter an Campaign Name'
                return
            }
            
            if (isDate < 0 ) {
                this.isErrorModalCampaign = true
                this.msgErrorModalCampaign = 'Please check Start Date, End Date'
                return
            }
        
            const isCampaignName = await campaign.isCampaignName(this.form.camapignName)
            if (isCampaignName) {
                this.isErrorModalCampaign = true
                this.msgErrorModalCampaign = 'The campaign name already exists.'
                return
            }
            const resultCreated = await this.createCampaign()

            if (resultCreated === 'FAILED')
                return
            
            this.dialog = false
            this.SNACK_BAR({show: true, text: 'CAMPAIGN CREATED', status: ''})
            this.initialize()
        },

        async save() {
            const isDate = dayjs(this.form.startDateEndDate[1]).diff(dayjs(this.form.startDateEndDate[0]), 'day')
            if (this.form.camapignName === "") {
                this.isErrorModalCampaign = true
                this.msgErrorModalCampaign = 'Please enter an Campaign Name'
                return
            }
            
            if (isDate < 0 ) {
                this.isErrorModalCampaign = true
                this.msgErrorModalCampaign = 'Please check Start Date, End Date'
                return
            }
        
            if (this.editedIndex > -1) {
                const resultUpdated = await this.updateCampaign()
                if (resultUpdated === 'FAILED')
                    return 
            } else {

                const isCampaignName = await campaign.isCampaignName(this.form.camapignName)
                if (isCampaignName) {
                    this.isErrorModalCampaign = true
                    this.msgErrorModalCampaign = 'The campaign name already exists.'
                    return
                }
                const resultCreated = await this.createCampaign()

                if (resultCreated === 'FAILED')
                    return
            }
            this.SNACK_BAR({show: true, text: 'username or password not match', status: ''})
            this.dialog = false
        },
        async createCampaign() {
            let imageName = ""
            if (this.form.imageFile.length != 0) {
                const responseUploadBanner = await campaign.uploadBannerCampaign(this.form.imageFile)
                if (responseUploadBanner.status !== 'SUCCESS') {
                    this.isErrorModalCampaign = true
                    this.msgErrorModalCampaign = responseUploadBanner.msg.join(',')
                    return 'FAILED'
                }
                imageName = responseUploadBanner.msg
            }
            const createCampaign = await campaign.createCampaign({
                code: this.form.camapignName,
                headerBanner: imageName,
                startDate: this.form.startDateEndDate[0],
                endDate: this.form.startDateEndDate[1],
                status: this.form.status === true ? "use" : "unuse",
                condition: this.form.conditionText,
            })
            return 'SUCCESS'
        },
    }
}
</script>

<style>

</style>