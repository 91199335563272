<template>
  <div>
		<!-- Voucher Image -->
		<VoucherImage :voucherImages="voucherImages" :loadVoucherImage="loadVoucherImage" :snackbarAlert="snackbarAlert"></VoucherImage>
		<!-- /Voucher Image -->
		<v-card>
			<v-card-title>
				VOUCHER
				<v-row justify="end">
					<v-btn
						depressed
						color="primary" @click="exportExcel"
					> <v-icon left small >fas fa-file-csv</v-icon> Export Excel </v-btn>
					<div style="width: 10px;"></div>
					<VoucherUploadCSV :reloadData="reloadData" :snackbarAlert="snackbarAlert"></VoucherUploadCSV>
					<div style="width: 10px;"></div>
					<VoucherRemove :reloadData="reloadData" :selected="selected" :snackbarAlert="snackbarAlert"></VoucherRemove>
					<div style="width: 10px;"></div>
					<VoucherLinkTemplate></VoucherLinkTemplate>
				</v-row>
			</v-card-title>
		<v-data-table
			v-model="selected"
			:headers="headers"
			:items="vouchers"
			item-key="id"
			sort-by="usedDate"
			class="elevation-1"
			show-select
			:search="search"
			:custom-filter="filterOnlyCapsText"
		>
		<template v-slot:top>
			<v-toolbar flat>
				<v-toolbar-title>SEARCH</v-toolbar-title>
				<v-divider
					class="mx-4"
					inset
					vertical
					>
				</v-divider>
				<v-text-field
					v-model="search"
					append-icon="mdi-magnify"
					label="Search all fields"
					single-line
					hide-details
				>
				</v-text-field>
			</v-toolbar>
		</template>

		<template v-slot:[`item.actions`]="{ item }">
			<v-btn color="primary" dark small @click="openDialogEditVoucher(item)"><v-icon left small >fas fa-edit</v-icon> Edit</v-btn>
		</template>

    </v-data-table>

    <v-snackbar
      v-model="snackbar"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="indigo"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
	<VoucherEdit 
		:formEditVoucher="formEditVoucher" 
		:dialogEditVoucher="dialogEditVoucher" 
		:closeDialogEditVoucher="closeDialogEditVoucher"
		:reloadData="reloadData"
		:snackbarAlert="snackbarAlert "
	></VoucherEdit>
	</v-card>
  </div>
</template>
<script>
import dayjs from 'dayjs'
import XLSX from 'xlsx'
import * as voucher from '../services/voucher.js'
import VoucherUploadCSV from '../components/VoucherUploadCSV'
import VoucherRemove from '../components/VoucherRemove'
import VoucherImage from '../components/VoucherImage'
import VoucherEdit from '../components/VoucherEdit'
import VoucherLinkTemplate from '../components/VoucherLinkTemplate'
import VoucherCreate from '../components/VoucherCreate'

export default {
	components: {
		VoucherUploadCSV,
		VoucherRemove,
		VoucherImage,
		VoucherEdit,
		VoucherLinkTemplate,
		VoucherCreate
	},
    data: () => ({
		snackbar: false,
		snackbarText: '',
		selected: [],
		dialogEditVoucher: false,
		search: '',
		voucherImages: [],
		formEditVoucher: {
			voucherId: '',
			mobile: '',
			idCard: '',
			voucherCode: '',
			voucherValue: '',
			isUsed: false,
			errorShow: false,
		},
		headers: [
			{ text: 'Mobile', value: 'mobile',  },
			{ text: 'ID Card', value: 'idcard',  },
			{ text: 'Voucher Code', value: 'voucherCode' },
			{ text: 'Voucher Value', value: 'voucherValue' },
			{ text: 'Use Date', value: 'usedDate' },
			{ text: 'Actions', value: 'actions', sortable: false },
		],
		vouchers: [],
    }),
    created () {
      	this.initialize()
      	this.loadVoucherImage()
    },
    methods: {
		snackbarAlert (state, text) {
			this.snackbar = state
			this.snackbarText = text
		},
		reloadData () {
			this.initialize()
			this.loadVoucherImage()
			this.selected = []
		},
		async initialize () {
			const campaignId = this.$route.params.campaingId
			const response = await voucher.getVoucherByCampaignId(campaignId)
			response.map(data => {
				data.usedDate = data.usedDate !== null ? dayjs(data.usedDate).format("YYYY-MM-DD H:m:s") : ''
				return data
			})
			this.vouchers = response
		},
		async loadVoucherImage () {
			const campaignId = this.$route.params.campaingId
			this.voucherImages = []
			let response = await voucher.getVoucherImageByCampaignId(campaignId)
			this.voucherImages = response.map(data => {
			data.imageUrl = data.imageUrl.split("?")[0]
			return data
			})
		},
		closeDialogEditVoucher () {
			this.dialogEditVoucher = false
		},
		openDialogEditVoucher (item) {
			this.formEditVoucher = {
				voucherId: item.id,
				mobile: item.mobile,
				idCard: item.idcard,
				voucherCode: item.voucherCode,
				voucherValue: item.voucherValue,
				isUsed: item.usedDate === '' ? false : true
			}
			this.dialogEditVoucher = true
		},
		filterOnlyCapsText(value, search, item) {
			let searchText = value != null && search.toLocaleUpperCase() != null && typeof value === "string" && value
				.toString()
				.toLocaleUpperCase()
				.indexOf(search.toLocaleUpperCase()) !== -1
			if (search.length > 0 && value != null && search.toLocaleUpperCase() != null && typeof value === "string") {
				return new RegExp("^"+search.toLocaleUpperCase()+"").test(value.toLocaleUpperCase())
			}
			return false
		},
		exportExcel() {
			const voucher = this.vouchers.map(data => {
			let newJson = []
				newJson.mobile = data.mobile
				newJson.idcard = data.idcard
				newJson.voucherCode = data.voucherCode
				newJson.voucherValue = data.voucherValue
				newJson.usedDate = data.usedDate
				return newJson
			})
			const dataWS = XLSX.utils.json_to_sheet(voucher)
			const wb = XLSX.utils.book_new()
			XLSX.utils.book_append_sheet(wb, dataWS)
			XLSX.writeFile(wb,'export.xlsx')
		}
	}
  }
</script>