<template>
    <v-btn
        color="secondary"
        dark
        class="mb-2"
        @click="templateDownload"
    >
        <v-icon left small >fas fa-file-csv</v-icon> Template .CSV
    </v-btn>
</template>

<script>
import config from '../services/config'

export default {
    methods:{
        templateDownload () {
			window.location.assign(`${config.URL_UPLOAD}/uploads/template/template.csv`)
		}
    }
}
</script>